import React from "react"

import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../style/styles.css"

// component
import Img from "gatsby-image"

// icons
import { FiMail } from "react-icons/fi"
import { FiPhoneCall } from "react-icons/fi"
import { FiMapPin } from "react-icons/fi"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <section className="w-full h-164 md:h-96 sm:h-96 sm:h-64 xs:h-64">
      <div
        className="absolute w-full h-164 md:h-96 sm:h-96 sm:h-64 xs:h-64 overflow-hidden"
        style={{ zIndex: "-1000" }}
      >
        <Img
          className="xl:h-164 lg:h-164 md:h-128"
          fluid={data.allFile.edges[0].node.childImageSharp.fluid}
          alt="Terminal A and Terminal B at the Port of Miami"
        />
      </div>
      <div className="container mx-auto px-5 pt-20 h-full flex justify-center items-center text-white">
        <div className="text-white">
          <h1 className="text-6xl md:text-5xl sm:text-5xl xs:text-3xl sbfont xl:-mt-32 lg:-mt-32 mb-0">
            Alphacladding
          </h1>
          <p className="lg:text-lg xl:text-lg text-center font-semibold">
            A Building's Shield, Made With Art.
          </p>
        </div>
      </div>
    </section>
    <section className="w-full pt-20 xs:pt-10 pb-6 bg-gray-200">
      <div className="container mx-auto px-5">
        <h3 className="font-light text-lg">
          High-quality{" "}
          <b>
            <i>Design, Fabrication & Installation</i>
          </b>{" "}
          solutions for the façade of your building, enabled by hands
          on-ownership.
        </h3>
        <p className="text-gray-600">
          Whether it be a rehab to an existing façade, or an entirely new,
          complex façade that completely transform your building, we work hand
          in hand with the lead architect to create a work of art that serves as
          a true expression of individuality for generations to come. We excel
          at custom designs, and we have the capability to work around your
          needs. The quality of our work is handled and approved by the founders
          at every stage of the process. Design, development, manufacturing,
          installation, and coordination are all managed directly by one of our
          owners. Alphacladding’s team environment is second to none. The
          majority of our team members have worked in conjunction with our
          owners for more than a decade. During which they have seen and
          overcome many projects and obstacles.
        </p>
      </div>
    </section>
    <section className="w-full pt-10 pb-6">
      <div className="container mx-auto px-5 flex flex-wrap-reverse justify-between">
        <iframe
          title="map"
          className="w-1/2 md:w-full sm:w-full xs:w-full rounded-lg"
          height="400"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=11001%20NW%2033rd%20St%20Doral%2C%20FL%2033172&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
        ></iframe>
        <div className="flex flex-col items-start w-5/12 md:w-full sm:w-full xs:w-full mb-4">
          <h1 className="m-0">Contact Us</h1>
          <p className="mt-2 text-gray-500">We would love to hear from you</p>

          <ul className="list-none pl-0 m-0 w-full" id="contact">
            <li className="flex items-center">
              <span className="text-xl rounded-lg bg-green-500 p-2 text-white mr-4">
                <FiPhoneCall />
              </span>
              <a href="tel:+17866154245" className="text-lg text-gray-600">
                (786) 615-4245
              </a>
            </li>
            <li className="flex items-center">
              <span className="text-xl rounded-lg bg-green-500 p-2 text-white mr-4">
                <FiMail />
              </span>
              <a
                href="mailto:conference@alphacladding.com"
                className="text-lg text-gray-600"
              >
                conference@alphacladding.com
              </a>
            </li>
            <li className="flex items-center">
              <span className="text-xl rounded-lg bg-green-500 p-2 text-white mr-4">
                <FiMapPin />
              </span>
              <a
                href="geo:25.804573,-80.375069"
                className="text-lg text-gray-600"
              >
                11001 NW 33rd St, Doral, FL 33172
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </Layout>
)

export const query = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/images/landing/landing/" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 4000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default IndexPage
